import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import "./style.css"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Select from 'react-select';
import { toast } from "react-toastify"
import ProductsService from "../../services/ProductsService"
import ProductCard from "../../common/ProductCard";
import RentModal from "../RentModal";
import Loader from "../../common/Loader";

const Product = () => {
    const [product, setProduct] = useState({})
    const [amount, setAmount] = useState(1)
    const [rentModal, setRentModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')
    const [variants, setVariants] = useState([])
    const [variantsIds, setVariantsIds] = useState([])
    const [fixedIds, setFixedIds] = useState([])
    const location = useLocation()
    const {t} = useTranslation()
    const [custom, setCustom] = useState(false)
    const [loading, setLoading] = useState(false)
    const [shouldUpdate, setShouldUpdate] = useState(false)
    const lang = useSelector(state => state?.lang?.lang)
    const [categoryId, setCategoryId] = useState(null)
    const [similarProduct, setSimilarProduct] = useState([])
    const productsService = new ProductsService()

    useEffect(()=>{
        let id = location?.pathname?.split('/product/')[1]
        setLoading(true)
        productsService.getProdust(id)?.then(res=>{
            if(res?.status === 200){
                setProduct(res?.data?.data?.product)
                setSelectedImage(res?.data?.data?.product.product_images[0]?.url)
                setCategoryId(res?.data?.data?.product?.category_id)
                if(res?.data?.data?.variant?.length > 0) {
                        setVariants(res?.data?.data?.variant)
                        let ids = res?.data?.data?.variant?.map( variant => variant?.variant_values?.find(val => val?.isSelected).id )
                        setVariantsIds(ids)
                        setFixedIds(ids)
                }
                setLoading(false)
            }
        }).catch((e)=> console.error(e))
    },[location])

    useEffect(()=>{
        if(!!categoryId){
            let params ={
                category_id: categoryId,
                offset: 0,
                limit: 15
            }
            productsService?.getList(params).then(res=>{
                if(res?.status){
                    setSimilarProduct(res?.data?.data?.data?.filter(prod => prod?.id !== product?.id))
                }
            })
        }
    }, [categoryId])

    useEffect(()=>{
        if(custom){
                productsService.changeProduct(product?.code, variantsIds.map(id => `variant_value_ids=${id}`).join('&'))?.then(res=>{
                    if(res?.status === 200){
                        setProduct(res?.data?.data?.product)
                        setSelectedImage(res?.data?.data?.product.product_images[0]?.url)
                        if(res?.data?.data?.variant?.length > 0) {
                            setVariants(res?.data?.data?.variant)
                            let ids = res?.data?.data?.variant?.map( variant => variant?.variant_values?.find(val => val?.isSelected).id )
                            setVariantsIds(ids)
                            setFixedIds(ids)
                        }
                    }
                }).catch(e=> {
                    setVariantsIds(fixedIds)
                    toast.error(e.response?.data?.message?.replaceAll('_', ' '))
                })
        }
    },[shouldUpdate])

    if(loading){
        return <Loader />
    }

    return <div>
        <div className="container">
            <div className="row product">
                <div className="col-md-6 text-center">
                    <img src={selectedImage} alt='product' className="w-75 main-img" />
                    <div className="px-5">
                    {product?.product_images?.length > 1 && <Swiper
                        slidesPerView={4}
                        spaceBetween={5}
                        pagination={{
                        clickable: true,
                        }}
                        className="mySwiper mt-4 d-flex flex-column"
                        style={{direction: 'ltr'}}
                        modules={[Pagination]}
                    >
                        {product?.product_images?.map((img,index)=>{ //product?.product_images
                            return <SwiperSlide style={{height: 'auto'}} key={index}>
                                <img
                                    className="w-75 h-100"  
                                    src={img?.url} 
                                    alt={index} 
                                    onClick={()=> setSelectedImage(img?.url)}//
                                />
                            </SwiperSlide>
                        })}
                    </Swiper>}
                    </div>
                </div>
                <div className="col-md-6">
                    <h1>{lang==='en' ? product?.name_en : product?.name_ar}</h1>
                    {product?.offer && <div className="d-flex align-items-center mb-3" style={{gap: "16px"}}>
                        <p className="old-price m-0">{product.price} {t("KWD")}</p>
                        <p className="discount mb-0">-{product.price-product.offerPrice} {t("KWD")}</p>
                    </div>}
                    {product?.offer && <p className="price">{product?.offerPrice?.toFixed(3)} {t("KWD / Per Day")}</p>}
                    {!product?.offer && <p style={{
                        fontSize: '16px',
                        margin: '0',
                        marginTop: '1rem'
                    }}>{product?.second_price?.toFixed(3)} {t("KWD / Per Days")}</p>}
                    {!product?.offer && <p className="price">{product?.price?.toFixed(3)} {t("KWD / Per Day")}</p>}
                    
                    {variants?.map((variant, index)=> {
                        return <div className="variant mb-3" key={index}>
                            <p className="mb-1">{lang==='en' ? variant?.name_en : variant?.name_ar}</p>
                            <div className="variant-values"
                            style={{gridTemplateColumns: variant?.name_ar === 'اللون' ? "auto auto auto auto auto" : "auto auto auto"}}>
                                {(variant?.name_ar === 'اللون' || variant?.name_en === 'color') ? variant?.variant_values?.map((val, ind)=> {
                                    return <div className="value position-relative mb-4" key={ind}>
                                    <span 
                                        onClick={()=> {
                                            setCustom(true)
                                            setShouldUpdate(prev => !prev)
                                            let update = variantsIds?.map((id, i) => {
                                                if(i === index){
                                                    return val?.id
                                                } else {
                                                    return id
                                                }
                                            })
                                            setVariantsIds(update)
                                        }}
                                        style={{
                                        position: 'absolute', cursor: 'pointer',
                                        width: '30px', height: '30px',
                                        border: '1px solid #dedede',
                                        backgroundColor: val?.value_en
                                        }}>
                                        {val?.isSelected && <span style={{
                                        position: 'absolute', borderRadius: '50%',
                                        width: '15px', height: '15px',
                                        border: '2px solid #dedede',
                                        backgroundColor: '#fff',
                                        top: '50%',  left: '50%',
                                        transform: 'translate(-50%, -50%)'
                                    }}></span>}
                                    </span>
                                    </div>
                                }) :
                                <>
                                <Select
                                    value={{
                                        ...variant?.variant_values?.find(v=> v.isSelected),
                                        label: lang==='en' ? variant?.variant_values?.find(v=> v.isSelected).value_en : variant?.variant_values?.find(v=> v.isSelected).value_ar,
                                        value: variant?.variant_values?.find(v=> v.isSelected).value_en,
                                    }}
                                    placeholder={t("Select")}
                                    options={variant?.variant_values?.map(val=> {
                                        return {
                                            ...val,
                                            label: lang==='en' ? val?.value_en : val?.value_ar,
                                            value: val?.value_en
                                        }
                                    })}
                                    onChange={(e)=> {
                                        let update = variantsIds?.map((id, i) => {
                                                if(i === index){
                                                    return e?.id
                                                } else {
                                                    return id
                                                }
                                            })
                                        setCustom(true)
                                        setVariantsIds(update)
                                        setShouldUpdate(prev => !prev)
                                    }}
                                />
                                </>}
                            </div>
                        </div>
                    })}

                    <div className="mt-4">
                        <label className="mb-1" style={{color: '#555'}}>{t("Description")}:</label>
                        <p className="description">{lang==='en' ? product?.description_en : product?.description_ar}</p>
                    </div>
                    {product?.amount > 0 ? <div className="d-flex" style={{gap: '22px'}}>

                        <div className="amounts d-flex" style={{alignItems: 'center'}}>
                            <button 
                                disabled={product?.amount === amount}
                                style={{cursor: (product?.amount === amount) ? 'not-allowed' : 'pointer'}}
                                onClick={()=> setAmount(prev => ++prev)}
                                className="btn btn-outline-secondary" 
                            >+</button>
                            <span style={{
                                marginRight: '12px',
                                marginLeft: '12px'
                            }}>{amount}</span>
                            <button
                                onClick={()=> {
                                    setAmount(prev=> --prev)
                                }}
                                className="btn btn-outline-secondary" 
                                disabled={amount === 1}
                            >-</button>
                        </div>

                        <button onClick={()=> setRentModal(true)} className="buy">{t("Add To Cart") }</button>
                    </div> : <h5 className="text-danger">{t("out of stock")}</h5>}
                </div>
            </div>

            {!!similarProduct?.length && <div className="similar_products">
            <h1 className='mb-3'>{t("you may also like")}</h1>
            <div>
            <Swiper
                slidesPerView={2}
                spaceBetween={10}
                pagination={{
                clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                breakpoints={{
                    640: { slidesPerView: 2, spaceBetween: 10 },
                    768: { slidesPerView: 3, spaceBetween: 20 },
                    1024: { slidesPerView: 3.5, spaceBetween: 30 },
                  }}
            >
                {similarProduct?.map((item, index) => {
                return <SwiperSlide key={index}>
                    <ProductCard product={item} />
                </SwiperSlide>
                })}
            </Swiper>
            </div>
        </div>}
        </div>
        {rentModal && <RentModal
          rentModal={rentModal}
          setRentModal={()=> setRentModal(false)}
          amount={amount}
          item={product}
        />}
    </div>
}
export default Product